import {
  createComponentAdmin,
  styleAttrUi,
  OptionalString,
  SubscribesTo,
  PublishesTo,
  StringEnum,
  ModuleCategory,
  borderSchema,
  typographySchema,
  type ContentModule,
  type ModuleProperties,
  type SchemaTypeHelper,
  type SchemaInstructionsHelper,
} from '@backstage-components/base';
import {
  schema as buttonSchema,
  uiSchema as buttonUiSchema,
} from '@backstage-components/button';
import {schema as textInputSchema} from '@backstage-components/text-input';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'AccessCode';
export const name = 'Access Code';
export const description = reactName;
const category: ModuleCategory = 'preset';

const magicLinkKeySchema = Type.String({
  title: 'Key for magic link',
  default: 'ac',
  description:
    'If magic link is enabled, you can provide your own key for the access code in the URL. Only alphanumeric characters, and underscores are allowed. Defaults to "ac". (e.g. my-site.com/my-page?ac=myAccessCode)',
  format: 'regex',
  pattern: '^[a-zA-Z0-9_]+$',
});

const resendTemplateSchema = Type.String({
  title: 'SendGrid Template Id',
  default: 'd-dcb0e24da5ab42e799ff1650c5083095',
  description:
    'If resend my code is enabled, this is the SendGrid template which will be triggered when the access code is sent to the guest',
  format: 'regex',
  pattern: '^d-[a-z0-9]{32}',
});

export const schema = Type.Object(
  {
    magicLinkSupport: StringEnum(
      ['none', 'auto-fill', 'auto-submit'] as const,
      {
        title: 'How to treat magic links',
        description:
          '"auto-fill" fills in the code, "auto-submit" fills in the code and submits for verification. For auto-submit to work "Show terms and conditions checkbox" must be unchecked.',
        default: 'none',
      }
    ),
    title: OptionalString({title: 'Title', default: 'WELCOME'}),
    titleColor: OptionalString({title: 'Title Color', default: '#000000'}),
    subtitle: OptionalString({
      title: 'Subtitle',
      default: 'Enter your access code below.',
    }),
    subtitleColor: OptionalString({
      title: 'Subtitle Color',
      default: '#000000',
    }),
    accessCodeErrorMessage: OptionalString({
      title: 'Error message for wrong access code',
      default: 'Incorrect access code provided',
    }),
    showResendLink: Type.Optional(
      Type.Boolean({title: 'Show resend link?', default: false})
    ),
    submitButtonProps: Type.Optional(
      Type.Intersect(
        [
          Type.Omit(
            buttonSchema,
            ['href', 'border', 'spacing', 'typography', 'animationStates'],
            {definitions: {}}
          ),
          Type.Object({
            typography: Type.Optional(
              Type.Pick(typographySchema, ['textColor'])
            ),
            border: Type.Optional(Type.Pick(borderSchema, ['borderRadius'])),
          }),
        ],
        {
          default: {
            border: {
              borderRadius: '100px',
            },
            children: 'ENTER',
            ariaLabel: 'ENTER',
          },
        }
      )
    ),
    codeTextInputProps: Type.Optional(
      Type.Object(textInputSchema.properties, {
        default: {
          name: 'accessCode',
          placeholder: 'Enter Passcode',
          inputType: 'text',
        },
      })
    ),
    // props to replicate the terms link/checkbox in accessCode
    showTermsCheckbox: Type.Optional(
      Type.Boolean({
        title: 'Show terms and conditions checkbox?',
        default: true,
      })
    ),
    termsLinkProps: Type.Optional(
      Type.Object(
        {
          content: Type.String({
            title: 'Label Content',
            default:
              'I agree to the <a href="#" target="_blank">Terms and Policy</a>',
          }),
        },
        {
          title: 'Checkbox Label',
          description:
            'Content for checkbox label that will be parsed by html-react-parser',
          default: {
            content:
              'I agree to the <a href="#" target="_blank">Terms and Policy</a>',
          },
        }
      )
    ),
    // props to replicate the resend code button in accessCode
    resendButtonProps: Type.Optional(
      Type.Intersect(
        [
          Type.Omit(
            buttonSchema,
            [
              'href',
              'sizing',
              'border',
              'spacing',
              'typography',
              'background',
              'animationStates',
              'styleAttr',
            ],
            {definitions: {}}
          ),
          Type.Object({
            typography: Type.Optional(
              Type.Pick(typographySchema, ['textColor', 'fontSize'])
            ),
          }),
        ],
        {
          title: 'Resend Button Props',
          default: {
            background: {
              buttonColor: 'transparent',
            },
            children: 'Resend My Code',
            typography: {
              fontSize: '10px',
            },
          },
        }
      )
    ),
    // custom validation message support
    validationMessages: Type.Optional(
      Type.Object(
        {
          accessCodeRequired: OptionalString({
            title: 'Access Code Required',
            description: 'Message displayed when code is empty',
            default: 'Code cannot be empty',
          }),
          checkboxRequired: OptionalString({
            title: 'Checkbox Required',
            description: 'Message displayed when terms checkbox is not checked',
            default: 'Please agree to the terms to continue',
          }),
          emailRequired: OptionalString({
            title: 'Email Required',
            description:
              'Message displayed during resend access code flow if an email address is not provided',
            default: 'Email must be provided',
          }),
        },
        {
          title: 'Custom Validation Messages',
          description:
            'Customize the validation messages displayed to a user of the Access Code component (for i18n, etc.).',
        }
      )
    ),
    resendAccessCodeFormContent: Type.Optional(
      Type.Object(
        {
          backButtonText: Type.String({
            title: 'Resend Access Code Form Back Button Text',
            description:
              'Text of the "back" button in the resend access code form',
            default: 'Back',
          }),
          title: Type.String({
            title: 'Resend Access Code Form Title',
            description: 'Form title for the resend access code form',
            default: 'Trouble signing in?',
          }),
          subtitle: Type.String({
            title: 'Resend Access Code Form Subtitle',
            description: 'Form subtitle for the resend access code form',
            default:
              "Please enter your email and we'll resend you your code. If you still experience issues, please contact our Support Team and we'll be.",
          }),
          submitButtonLabel: Type.String({
            title: 'Resend Access Code Submit Button Label',
            description:
              'Text displayed in the submit button when the resend access code form is shown',
            default: 'Submit',
          }),
          successMessage: Type.String({
            title: 'Resend Access Code Success',
            description:
              'Message displayed after the resend request has been successfully received',
            default: 'Please check your email for your code',
          }),
          placeholder: Type.String({
            title: 'Resend Access Code Placeholder',
            description:
              'Message displayed in the input prompting users what to enter',
            default: 'Enter Email',
          }),
          emailErrorMessage: OptionalString({
            title: 'Resend Access Code Error (e-mail not found)',
            description:
              'Message displayed on resend request failure, informing the user that the entered e-mail is not found',
            default: 'Unable to resend code, e-mail not found',
          }),
        },
        {
          title: 'Resend Access Code Form',
        }
      )
    ),
  },
  {
    dependencies: {
      magicLinkSupport: {
        oneOf: [
          {
            properties: {
              magicLinkSupport: {
                enum: ['auto-fill', 'auto-submit'],
              },
              magicLinkKey: magicLinkKeySchema,
            },
          },
        ],
      },
      showResendLink: {
        oneOf: [
          {
            properties: {
              showResendLink: {
                enum: [true],
              },
              // The `resendTemplate` key is used by the API when triggering
              // resend emails. If this key changes the `triggerResendAccessCode`
              // mutation likely also needs to change
              resendTemplate: resendTemplateSchema,
            },
          },
        ],
      },
    },
  }
);

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              [
                'General',
                [
                  'magicLinkSupport',
                  'magicLinkKey',
                  'title',
                  'titleColor',
                  'subtitle',
                  'subtitleColor',
                ],
              ],
              ['Submit Button', ['submitButtonProps']],
              ['Code Input', ['codeTextInputProps', 'accessCodeLength']],
              ['Terms and Conditions', ['showTermsCheckbox', 'termsLinkProps']],
              [
                'Localization',
                ['accessCodeErrorMessage', 'validationMessages'],
              ],
              [
                'Resend Access Code',
                [
                  'showResendLink',
                  'resendTemplate',
                  'resendButtonProps',
                  'resendAccessCodeFormContent',
                ],
              ],
            ],
          },
        ],
      ],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [['Custom Styles', ['styleAttr']]],
          },
        ],
      ],
      [
        'Animations',
        [
          {
            'ui:template': 'accordion',
            sections: [['Animations', ['animationStates']]],
          },
        ],
      ],
    ],
  },
  codeTextInputProps: {
    label: {'ui:widget': 'hidden'},
    name: {'ui:widget': 'hidden'},
    inputType: {'ui:widget': 'hidden'},
    ...styleAttrUi,
  },
  submitButtonProps: {
    'ui:groups': {
      'ui:template': 'accordion',
      sections: [
        ['Button Properties', ['children', 'hoverColor', 'ariaLabel']],
        ['Sizing', ['sizing']],
        ['Background', ['background']],
        ['Typography', ['typography']],
        ['Border', ['border']],
        ['Custom Styles', ['styleAttr']],
      ],
    },
    hoverColor: {
      'ui:widget': 'color',
    },
    sizing: {
      ...buttonUiSchema.sizing,
    },
    typography: {
      textColor: {
        'ui:widget': 'color',
      },
    },
    background: {
      buttonColor: {
        'ui:widget': 'color',
      },
    },
    ...styleAttrUi,
  },
  showResendLink: {'ui:widget': 'checkbox'},
  resendButtonProps: {
    hoverColor: {
      'ui:widget': 'color',
    },
    typography: {
      textColor: {
        'ui:widget': 'color',
      },
    },
    ...styleAttrUi,
    'ui:order': ['*', 'styleAttr'],
  },
  termsLinkProps: {
    content: {
      'ui:widget': 'modalTextareaWidget',
      'ui:options': {
        buttonTitle: 'Content',
        editor: 'TinyMCE',
        /**
         * Hard coding this api key as a stop gap since TinyMCE has other safeguards in place around which domains can be whitelisted to display the rich text editor.
         * Planning to remove this api key as part of https://lcdigital.atlassian.net/browse/DLB-1402
         */
        tinyApiKey: 'mb6fyq4wtavfomcdjm3guo04keatf4msk5b5sd1p81pvtdrw',
      },
    },
  },
  subtitle: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 5,
    },
  },
  titleColor: {
    'ui:widget': 'color',
  },
  subtitleColor: {
    'ui:widget': 'color',
  },
  'ui:order': ['magicLinkSupport', 'magicLinkKey', '*'],
};

type BaseSchemaType = Static<typeof schema>;

/**
 * Subset of `SchemaType` that omits props that don't need defaults, and makes the rest required.
 */
type RequiredDefaultProps = Required<
  Omit<BaseSchemaType, 'styleAttr' | 'animationStates'>
>;

/**
 * Subset of the `SchemaType` for required validation messages.
 */
type ValidationMessages = Exclude<
  Required<BaseSchemaType['validationMessages']>,
  undefined
>;

/**
 * Type alias for the properties from `SchemaType` which are required to have
 * defaults but not required in the schema, and properties which are not accounted for
 * in the schema because they are conditionally added.
 */
type ExtraDefaultProps = {
  resendTemplate: Static<typeof resendTemplateSchema>;
  validationMessages: ValidationMessages;
};

/**
 * Type alias that makes the `resendAccessCodeFormContent` and
 * `validationMessages` properties required to ensure "defaults" always exist
 * for these properties.
 */
type DefaultFieldData = RequiredDefaultProps & ExtraDefaultProps;

export const defaultFieldData: DefaultFieldData = {
  title: schema.properties.title.default,
  titleColor: schema.properties.titleColor.default,
  subtitle: schema.properties.subtitle.default,
  subtitleColor: schema.properties.subtitleColor.default,
  showTermsCheckbox: schema.properties.showTermsCheckbox.default,
  showResendLink: schema.properties.showResendLink.default,
  termsLinkProps: schema.properties.termsLinkProps.default,
  submitButtonProps: schema.properties.submitButtonProps.default,
  codeTextInputProps: schema.properties.codeTextInputProps.default,
  resendButtonProps: schema.properties.resendButtonProps.default,
  accessCodeErrorMessage: schema.properties.accessCodeErrorMessage.default,
  magicLinkSupport: schema.properties.magicLinkSupport.default,
  resendAccessCodeFormContent: {
    backButtonText:
      schema.properties.resendAccessCodeFormContent.properties.backButtonText
        .default,
    submitButtonLabel:
      schema.properties.resendAccessCodeFormContent.properties.submitButtonLabel
        .default,
    subtitle:
      schema.properties.resendAccessCodeFormContent.properties.subtitle.default,
    successMessage:
      schema.properties.resendAccessCodeFormContent.properties.successMessage
        .default,
    title:
      schema.properties.resendAccessCodeFormContent.properties.title.default,
    placeholder:
      schema.properties.resendAccessCodeFormContent.properties.placeholder
        .default,
    emailErrorMessage:
      schema.properties.resendAccessCodeFormContent.properties.emailErrorMessage
        .default,
  },
  resendTemplate: resendTemplateSchema.default,
  validationMessages: {
    accessCodeRequired:
      schema.properties.validationMessages.properties.accessCodeRequired
        .default,
    checkboxRequired:
      schema.properties.validationMessages.properties.checkboxRequired.default,
    emailRequired:
      schema.properties.validationMessages.properties.emailRequired.default,
  },
};

export const AccessCodeInstructionSchema = Type.Union([
  SubscribesTo({
    topic: `${reactName}:success`,
    description: 'Access code was successfully verified',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
        tags: Type.Array(Type.String(), {
          description: 'Tags associated with the attendee',
        }),
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:failure`,
    description: 'Access code could not be verified',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:on-resend-failure`,
    description: 'An error occured while trying to resend an access code.',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason why the resend email was not sent.',
      }),
      code: OptionalString({
        description: 'Indicates the code(type) of the error if available',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:on-resend-success`,
    description: 'Resend email operation succeeded.',
    meta: {},
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:reset`,
    description:
      'Resets the access code component (local only) clearing the form',
  }),
  PublishesTo({
    topic: `${reactName}:on-failure`,
    description: 'Indicates an unsuccessful verification has occurred.',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was unable to be verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-success`,
    description: 'Indicates a successful verification has occurred.',
    meta: {
      attendeeId: Type.String({
        description: 'Unique identifier for the verified Attendee',
      }),
      attendeeName: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee name if available, null otherwise',
      }),
      attendeeEmail: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee email address if available, null otherwise',
      }),
      attendeeTags: Type.String({
        description:
          'Comma separated list of tags associated with the attendee',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:verify`,
    description: 'Requests the given access code be verified for the show',
    meta: {
      accessCode: Type.String({
        description: 'Show access code to be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which access code will be checked',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  PublishesTo({
    topic: `${reactName}:resend`,
    description:
      'Requests that an access code be re-sent to a provided email (if code exists)',
    meta: {
      email: Type.String({
        description: 'Email address to check and resend to',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which access code will be checked',
      }),
      coreId: Type.String({
        description:
          'The core whose data will be accessed to find resendTemplate',
        format: 'uuid',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  PublishesTo({
    topic: `${reactName}:resend-view`,
    description: 'Display the resend access code form',
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  PublishesTo({
    topic: `${reactName}:login-view`,
    description: 'Display the access code verification form',
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
]);

export const ComponentDefinition = createComponentAdmin({
  id: 'e3dbcc42-2fbe-44ed-97b9-95d795eceac5',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions: AccessCodeInstructionSchema,
  category,
})
  .withAnalyticsInstructionMask((instruction) => {
    switch (instruction.type) {
      case 'AccessCode:on-success':
        return {
          type: instruction.type,
          meta: {
            about: instruction.meta.about,
            showId: instruction.meta.showId,
          },
        };
      case 'AccessCode:resend':
        return {
          type: instruction.type,
          meta: {
            about: instruction.meta.about,
            coreId: instruction.meta.coreId,
            showId: instruction.meta.showId,
          },
        };
      case 'AccessCode:success':
        return {
          type: instruction.type,
          meta: {about: instruction.meta.about},
        };
      case 'AccessCode:verify':
        return {
          type: instruction.type,
          meta: {
            about: instruction.meta.about,
            showId: instruction.meta.showId,
          },
        };
      default:
        return instruction;
    }
  })
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;

export type AccessCodeInstruction = SchemaInstructionsHelper<
  typeof ComponentDefinition
>;

/**
 * `IAccessCodeProps` accounts for the property schemas of the `Button` module
 * and the `TextInput` module (more than the schema can) and also adds the
 * properties which are specified by `dependencies` access code module schema.
 */
export interface IAccessCodeProps extends ModuleProperties, SchemaType {
  magicLinkKey?: string;
}

export type AccessCodeComponentDefinition = ContentModule<
  'AccessCode',
  IAccessCodeProps & SchemaType
>;
